/* eslint-disable react/jsx-filename-extension */
import {useEffect} from 'react';
import * as styles from './404.module.css';
import ErrorImage from '../assets/images/not_found.svg';

const E404 = () => {
    useEffect(() => {
        const light = document.querySelectorAll('[data-light]');

        light.forEach(item => {
            item.classList.add(styles.blink);
        });
    }, []);

    return (
        <div className={styles.wrapper}>
            <ErrorImage/>
            <h2>Page not found</h2>
        </div>
    );
};

export default E404;
